import React from "react";
import ReactLogo from "../static/lchtgst_logo_only_black_paths.svg";
import { useIntl } from "gatsby-plugin-react-intl";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faVimeo } from '@fortawesome/free-brands-svg-icons'

const Contact = () => {

  const intl = useIntl();

  const srcSetSizeXXS = 100;
  const srcSetSizeXS = 200;
  const srcSetSizeS = 300;
  const srcSetSizeM = 400;
  const srcSetSizeL = 600;

  /**
   * Shuffles array in place.
   * @param {Array} a items An array containing the items.
   */
  const shuffle = (a) => {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
  }

  const randArr = shuffle(Array.from(Array(47).keys())); //set amount of total photos to choose from (highest number of photo in folder + 1)
  randArr.length = 24; //set amount of photos to show

  return (
    <div className="contact h-100">
      <div className="row h-100 no-gutters">
        <div className="col-lg-6 col-md-8 col-sm-10 contactText">
          <div className="contactLogoContainer">
            <div className="contactTitleLogo">
              <ReactLogo className="aboutLogo"/>
            </div>
            <div className="contactTitleHeader">
              <h1>
                {intl.formatMessage({ id: "contact" })}
              </h1>
            </div>
          </div>
          <div className="contactLeftContent">
            <div className="contactSection">
              <p className="contactLine">lichtgestalten<br></br>
                Kaiser & Schatz GbR<br></br>
                Rotebühlstr. 59a<br></br>
                70178 Stuttgart <br></br>
                <br></br>
                Studio München<br></br>
                Auenstr. 90 <br></br>
                80469 München
              </p>
            </div>
            <div className="contactSection">
              <p className="contactLine">{intl.formatMessage({ id: "contact" })}:<br></br>
                <a className="contactMailTo" href="mailto:info@lichtgestalten.li">m: info@lichtgestalten.li</a>
              </p>
            </div>
            <div className="contactSection">
              <p className="contactLine">
                philipp kaiser<br></br>
                <a className="contactMailTo" href="mailto:philipp@lichtgestalten.li">m: philipp@lichtgestalten.li</a><br></br>
                t: 0176 31 40 15 85</p>
            </div>
            <div className="contactSection">
              <p className="contactLine">
                dominik schatz<br></br>
                <a className="contactMailTo" href="mailto:dominik@lichtgestalten.li">m: dominik@lichtgestalten.li</a><br></br>
                t: 0176 314 00 649</p>
            </div>
            <div className="contactSection">
              <div className="row">
                  <div className="col-2 socials">
                    <a className="socialLink" href="https://www.instagram.com/lichtgestalten_stgt/" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faInstagram} size="lg"/>
                    </a>
                  </div>
                  <div className="col-2 socials">
                    <a className="socialLink" href="https://de-de.facebook.com/visualisten.lichtgestalten" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faFacebook} size="lg"/>
                    </a>
                  </div>
                  <div className="col-2 socials">
                    <a className="socialLink" href="https://vimeo.com/visualslichtgestalten" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faVimeo} size="lg"/>
                    </a>
                  </div>
                  <div className="col socials">
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-18 col-md-16 col-sm-14 contactVGallery">
          {/*<Babylon />*/}
          {/*<ShadertoyReact fs={fragmentShader}/>*/}
          <div className="row">
            {
              randArr.map((value,index) => {
                return(
                <div className="col-lg-6 col-md-8 col-12 squareTiles" key={index}>
                  <img 
                  className="squareTilesImg" 
                  key={index}
                  alt={"lichtgestalten_making_of" + value}
                  sizes="@media (max-width: 996px) 25vw, 35vw"
                  srcSet={
                    "https://lichtgst.uber.space/photos/tiles/" + value + '_' + `${srcSetSizeL}` + ".jpg" + ' ' + `${srcSetSizeL}` + "w, " +
                    "https://lichtgst.uber.space/photos/tiles/" + value + '_' + `${srcSetSizeM}` + ".jpg" + ' ' + `${srcSetSizeM}` + "w, " +
                    "https://lichtgst.uber.space/photos/tiles/" + value + '_' + `${srcSetSizeS}` + ".jpg" + ' ' + `${srcSetSizeS}` + "w, " +
                    "https://lichtgst.uber.space/photos/tiles/" + value + '_' + `${srcSetSizeXS}` + ".jpg" + ' ' + `${srcSetSizeXS}` + "w, " +
                    "https://lichtgst.uber.space/photos/tiles/" + value + '_' + `${srcSetSizeXXS}` + ".jpg" + ' ' + `${srcSetSizeXXS}` + "w "
                  }>
                  </img>
                </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
