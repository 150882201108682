import React from "react"

import Layout from "../components/layout"
import Contact from "../components/contact"
import SEO from "../components/seo"

import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl";

const IndexPage = ({intl}) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: "titleContact" })} lang={intl.locale}/>
    <Contact></Contact>
  </Layout>
)

export default injectIntl(IndexPage)
